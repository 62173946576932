<template>
  <div id="imgContainer">
    <div class="motiv motiv01" v-for="(item, i) in images" :key="i">
      <img :src='item'>
    </div>
  </div>
</template>

<script>
  import moment from "moment"
  import { gsap } from 'gsap';
  import { CSSPlugin } from 'gsap/CSSPlugin'
  gsap.registerPlugin(CSSPlugin);
  export default {
    name: "MainBg",
    data() {
      return {
        images: []
      }
    },
    mounted() {
      this.updateBgColor()
    },
    methods: {
      updateBgImage (bgImage) {

        gsap.set('.motiv img',{xPercent:-50, yPercent:-50})

        const imgs = gsap.utils.toArray("#imgContainer .motiv");
        const next = 3; // time to change
        const fade = 2; // fade time

        gsap.set(imgs[0], {autoAlpha:1})

        let i = 2
        function crossfade(){

          gsap.timeline()
          .to(imgs[0], {autoAlpha:0, duration:fade})
          .to(imgs[1], {autoAlpha:1, duration:fade},0)

          if (i < bgImage.length) {
            imgs.push( imgs.shift() );
            gsap.delayedCall(next, crossfade);
            i ++
          }
        }
        gsap.delayedCall(next, crossfade);
      },
      updateBgColor () {
        const bgImage = ['winter.webp', 'spring.webp', 'summer.webp', 'autumn.webp']
        let nowDate = moment().format("M")
        if (nowDate === '12' || nowDate === '1' || nowDate === '2') {
          //winter
          bgImage.push('winter.webp')
        } else if (nowDate === '3' || nowDate === '4' || nowDate === '5') {
          //spring
          bgImage.push('spring.webp')
        } else if (nowDate === '6' || nowDate === '7' || nowDate === '8') {
          //summer
          bgImage.push('summer.webp')
        }

        bgImage.map((item) => {
          this.images.push(require(`../../public/bg/${item}`))
        })
        setTimeout(() => {
          this.updateBgImage(bgImage)
        }, 200)
      },
    }
  }
</script>

<style scoped>
  #imgContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
    overflow:hidden;
    object-fit: cover;
    z-index: -10;
  }

  #imgContainer .motiv{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
  }

  .motiv img{
    position: absolute;
    left: 50%;
    top: 50%;
    height: 111%;
  }
</style>
