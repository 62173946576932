<template>
  <div class="preloader">
    <header-loading/>
    <div class="preloader-text">
      <vue-skeleton-loader
        type="rect"
        :width="438"
        :height="21"
        :rounded="true"
        class="mt-6"
        :radius="5"
        animation="wave"
      />
    </div>
    <div class="preloader-main">
      <vue-skeleton-loader
        type="rect"
        :width="425"
        :height="40"
        :rounded="true"
        :radius="5"
        animation="wave"
      />
      <vue-skeleton-loader
        type="rect"
        :width="438"
        :height="21"
        :rounded="true"
        class="preloader-main-text"
        :radius="5"
        animation="wave"
      />
     <div class="preloader-main-items">
       <div v-for="i in 3" :key="i" class="preloader-main-items-item">
         <vue-skeleton-loader
           type="rect"
           :width="350"
           :height="405"
           :rounded="true"
           :radius="20"
           animation="wave"
         />
         <vue-skeleton-loader
           type="rect"
           :width="198"
           :height="30"
           :rounded="true"
           class="block"
           :radius="5"
           animation="wave"
         />
         <div class="block">
           <div class="block-item" v-for="i in 4" :key="i">
             <vue-skeleton-loader
               type="rect"
               :width="43"
               :height="53"
               :rounded="true"
               :radius="5"
               animation="wave"
             />
             <vue-skeleton-loader
               type="rect"
               :width="34"
               :height="16"
               :rounded="true"
               class="mt-10"
               :radius="5"
               animation="wave"
             />
           </div>
         </div>
         <vue-skeleton-loader
           type="rect"
           :width="160"
           :height="45"
           :rounded="true"
           class="block"
           :radius="5"
           animation="wave"
         />
       </div>
     </div>
    </div>
  </div>
</template>

<script>
  import VueSkeletonLoader from 'skeleton-loader-vue';
  import HeaderLoading from "./HeaderLoading";
  export default {
    name: 'MainLoading',
    props: ['status'],
    components: {HeaderLoading, VueSkeletonLoader},
    mounted() {
      document.body.style.overflowY = 'hidden'
      document.body.style.width = '99%'
    },
    beforeDestroy () {
      document.body.style.overflowY = 'auto'
      document.body.style.width = '100%'
    },
  }
</script>
<style scoped lang="scss">


  body, html, .preloader {
    z-index: 9999;
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;

    .mr-30 {
      margin-right: 30px;
    }


    &-text {
      display: flex;
      justify-content: center;
      padding: 5px;
    }

    &-main {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #e8e8e8;
      padding: 48px 0 184px;

      &-text {
        margin-top: 18px;
      }

      &-items {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 47px;

        &-item {
          border-radius: 20px;
          margin: 20px 22px;
          background: #e2e2e2;
          display: flex;
          align-items: center;
          flex-direction: column;

          .block {
            margin-top: 10px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;

            &-item {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin: 10px;

              .mt-10 {
                margin-top: 10px;
              }
            }
          }
        }
      }

    }
  }

</style>
