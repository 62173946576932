var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "imgContainer"
    }
  }, _vm._l(_vm.images, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "motiv motiv01"
    }, [_c('img', {
      attrs: {
        "src": item
      }
    })]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }