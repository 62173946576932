<template>
  <div>
<!--  <div class="header-info home">-->
<!--    <div class="header-info-wrapper">-->
<!--      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>-->
<!--    </div>-->
<!--  </div>-->
  <Main />
  <home-body :key="componentKey"/>
  </div>
</template>
<script>
import Main from '../components/Main.vue'
import HomeBody from "../components/HomeBody";
export default {
  name: 'Home',
  components: {
    HomeBody,
    Main,
  },
  data() {
    return {
      componentKey: 0,
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      let width = window.innerWidth;
      if (width > 850) {
        if (this.time) {
          clearInterval(this.time)
        }
        this.time = setTimeout(() => {
          this.componentKey += 1
          window.scrollTo({
            top: 0,
          });
        }, 100)
      } else {
        this.componentKey += 1
      }
    },
  }
}
</script>
<style scoped lang="scss">
.header-info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  &-info {
    .wrapper {
      text-align: center;
      width: 100%;
    }
    &.home {
      padding: 7px 0;
      font-size: 18px;
      line-height: 21px;
      color: #676767;
      background: #F7F7F7;
      .wrapper {
        justify-content: center;
      }
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
</style>
