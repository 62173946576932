var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loader ? _c('main-loading') : _vm._e(), _c('main', {
    staticClass: "main"
  }, [_c('main-bg'), _c('div', {
    staticClass: "wrapper"
  }, [_c('h1', {
    staticClass: "main-title"
  }, [_vm._v("Longest Auction Ever")]), _c('p', {
    staticClass: "main-subtitle"
  }, [_vm._v("We have created art woven from time")]), _vm._m(0), _c('AuctionCard')], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animation-wrapper"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": require("../../public/swipe.gif"),
      "alt": "",
      "id": "cursor"
    }
  })]);
}]

export { render, staticRenderFns }