<template>
  <div>
    <div class="about section">
      <div class="wrapper">
        <div class="about-info left text-animation pContent">
          <h2 class="about-info__title">What is going here?</h2>
          <div class="about-info__desc">
            <p>We have launched the longest auction on the Tezos blockchain featuring 111 NFTs.</p>
            <p><span>1-YEAR-Auction</span> - 100 winners</p>
            <p><span>10-YEAR-Auction</span> - 10 winners</p>
            <p><span>100-YEAR-Auction</span> - 1 winners</p>
          </div>
        </div>
        <img src="../../public/home/what-is-going-here.webp" alt="What is going here?" class="about-img pImage text-animation">
        <img src="../../public/home/philosophy.webp" alt="Philosophy" class="about-img pImage text-animation">
        <div class="about-info text-animation text-philosophy">
          <h2 class="about-info__title">Philosophy</h2>
          <div class="about-info__desc">
            <p>Our auctions are a tribute to the priceless essence of time, uniqueness, and creativity.
              By participating in these long-term auctions, you become part of a peculiar creative journey that results in the
              acquisition of a unique image of the NFT tree.
            </p>
            <p>Blockchain technology,
              due to its decentralized and secure nature, is here to stay and is likely to play a major role
              in the years to come. However, the value of the NFT lies not merely in the image itself,
              but in the process of acquiring it. Thus, time legitimizes the value of the prize.</p>
            <p>
              This is what makes the auction an exceptional event. Essentially, we have managed to create art woven from time.
            </p>
          </div>
        </div>
        <div class="about-info left text-animation pContent">
          <h2 class="about-info__title">Philosophy</h2>
          <div class="about-info__desc">
            <p>
              The lengthy life cycle of a tree is an evocative metaphor for the transience of time. To better reflect our idea, we have featured:
            </p>
            <ul>
              <li>Young trees for the one-year auction;</li>
              <li>Trees with a more resilient root system and a large crown for the 10-year auction;</li>
              <li>A tree with a robust trunk and dense foliage for the 100-year auction.</li>
            </ul>
            <p>  Each NFT tree is unique and represented according to the season in which you are placing your bet, as the changing of the seasons and the transience of time are tightly bound together.
            </p>
            <p>
              You can't play with time and you can't cheat. To compete for a tree of a particular season, you have to wait for that time of year and place your bet.

            </p>
          </div>
        </div>
        <img src="../../public/home/seasons.webp" alt="Seasons" class="about-img pImage text-animation">
      </div>
    </div>
    <div class="participate section">
      <div class="wrapper">
        <h2 class="participate-title text-animation text-top">How to participate?</h2>
        <div class="participate-content">
          <div class="participate-content__img left">
            <img src="../../public/home/connect-wallet.webp" alt="Connect wallet and make a bid" class="image img-top text-animation">
          </div>
          <div class="participate-content__info right semi-start">
            <h3 class="text-animation text-title">Connect wallet and make a bid</h3>
            <p class="text-animation text-top">Connect a wallet with the required amount of Tezos for the minimum bid. Place a bid on the page of the desired auction.</p>
          </div>
          <div class="participate-content__info left">
            <h3 class="text-animation text-title">NFT Reservation</h3>
            <p class="text-animation text-top">After you have placed a bid, you have successfully reserved an NFT with the corresponding time of the year.</p>
            <p class="text-animation text-top">For example, if you are betting in the summer, you will be competing for the summer NFT tree (even if you plan to increase the bid at a different time of year).</p>

            <div class="after"></div>
          </div>
          <div class="participate-content__img right">
            <img src="../../public/home/nft-reserve.webp" alt="NFT reserve" class="image img-top text-animation">
          </div>
          <div class="participate-content__img left">
            <img src="../../public/home/compete-for-places.webp" alt="Compete for the prize places" class="image img-top text-animation">
          </div>
          <div class="participate-content__info right">
            <h3 class="text-animation text-title">Be on the Winning Side of the Auction</h3>
            <p class="text-animation text-top">Keep an eye on the progress of the auction to stay among the winners!</p>
            <p class="text-animation text-top">If you are outbid, increase the bid or make a new one to get back in the winner's list.</p>
          </div>
          <div class="participate-content__info left semi-end">
            <h3 class="text-animation text-title">Get your NFT</h3>
            <p class="text-animation text-top">If you are one of the winners, open your Profile to see the NFT you have won.</p>
          </div>
          <div class="participate-content__img right">
            <img src="../../public/home/get-nft.webp" alt="Get your NFT" class="image img-top text-animation">
          </div>
        </div>
      </div>
    </div>
    <div class="choose choose-animation">
      <div class="wrapper">
        <div class="choose-frame choose-frame-animation auction-history">
          <div class="auction-history__cont" v-if="transactions && transactions.length">
<!--            <div class="auction-history__alert" v-if="!isConnected">-->
<!--              <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M13.6159 15.042L13.3582 11.1902C13.3078 10.4152 13.0434 9.16265 13.6353 8.53297C14.0857 8.04859 15.1523 7.96528 15.4759 8.63662C15.751 9.42551 15.8119 10.2733 15.6522 11.0934L15.3073 15.0585C15.2925 15.4317 15.2108 15.7992 15.0661 16.1435C15.0092 16.2549 14.9228 16.3486 14.8164 16.4145C14.71 16.4803 14.5876 16.5158 14.4625 16.5171C14.3374 16.5184 14.2143 16.4855 14.1066 16.4218C13.9988 16.3582 13.9106 16.2663 13.8513 16.1561C13.715 15.7993 13.6355 15.4234 13.6159 15.042ZM14.5081 20.3343C14.2026 20.3326 13.9093 20.2145 13.6877 20.0042C13.4662 19.7938 13.3331 19.507 13.3156 19.202C13.2981 18.897 13.3974 18.5968 13.5934 18.3624C13.7894 18.1281 14.0673 17.9772 14.3705 17.9405C14.5335 17.9208 14.6987 17.9347 14.8561 17.9813C15.0135 18.0279 15.1596 18.1063 15.2855 18.2116C15.4114 18.3169 15.5144 18.4468 15.5881 18.5935C15.6618 18.7401 15.7047 18.9003 15.7141 19.0641C15.7235 19.228 15.6993 19.392 15.6428 19.5461C15.5864 19.7003 15.499 19.8412 15.386 19.9602C15.273 20.0792 15.1368 20.1738 14.9858 20.2381C14.8348 20.3025 14.6722 20.3352 14.5081 20.3343Z" fill="#333333"/>-->
<!--                <path d="M25.3433 24.1252H3.65682C3.1473 24.1173 2.64862 23.9769 2.20992 23.7176C1.77123 23.4583 1.40768 23.0892 1.1551 22.6466C0.902519 22.204 0.76963 21.7033 0.769531 21.1937C0.769433 20.6841 0.902129 20.1833 1.15454 19.7406L12.0104 2.33896C12.2741 1.91505 12.6417 1.56549 13.0783 1.3234C13.5149 1.0813 14.0061 0.954698 14.5054 0.955571C15.0046 0.956444 15.4954 1.08477 15.9312 1.32839C16.3669 1.572 16.7333 1.92285 16.9955 2.34768L27.8339 19.7203C28.0906 20.1627 28.2271 20.6645 28.2299 21.176C28.2327 21.6874 28.1018 22.1907 27.85 22.636C27.5983 23.0812 27.2345 23.4529 26.7948 23.7141C26.355 23.9753 25.8547 24.117 25.3433 24.1252ZM13.6659 3.34452L2.8111 20.7462C2.73105 20.894 2.6905 21.0599 2.69337 21.2279C2.69625 21.396 2.74244 21.5604 2.82749 21.7054C2.91253 21.8504 3.03356 21.9709 3.17884 22.0554C3.32413 22.1399 3.48877 22.1855 3.65682 22.1877H25.3433C25.5131 22.1851 25.6794 22.1383 25.8257 22.0518C25.972 21.9654 26.0932 21.8423 26.1774 21.6947C26.2616 21.5472 26.3059 21.3802 26.3059 21.2103C26.3059 21.0404 26.2616 20.8734 26.1774 20.7258C26.1774 20.7258 15.339 3.35033 15.3341 3.34452C15.2449 3.20408 15.1217 3.08844 14.9759 3.00832C14.8301 2.9282 14.6664 2.88619 14.5 2.88619C14.3337 2.88619 14.17 2.9282 14.0242 3.00832C13.8784 3.08844 13.7551 3.20408 13.6659 3.34452Z" fill="#333333"/>-->
<!--              </svg>-->
<!--              <span>{{ $t("main.please") }}, <a href="#">{{ $t("main.connect_wallet") }}</a> {{ $t("main.to_make_bid") }} </span>-->
<!--            </div>-->
            <div class="auction-history__header" v-if="transactions && transactions.length">
              <div class="auction-history__col">{{ $t("table.date") }}</div>
              <!--            <div class="auction-history__col">{{ $t("table.auction") }}</div>-->
              <div class="auction-history__col">{{ $t("table.season") }}</div>
              <div class="auction-history__col">{{ $t("table.rank") }}</div>
              <div class="auction-history__col">{{ $t("table.bid") }}</div>
              <div class="auction-history__col">{{ $t("table.address") }}</div>
            </div>
            <div class="auction-history__items" v-if="transactions && transactions.length">
              <div v-for="(transaction, index) in transactions" :key="index" class="auction-history__item">
                <div class="auction-history__col">{{ transaction.value.ts | moment("from", "now")  }}</div>
                <!--              <div class="auction-history__col">{{transaction.year}} {{$t("table.year")}}</div>-->
                <div class="auction-history__col"><p>Season</p>{{seasonConverter(transaction.value.season)}}</div>
                <div class="auction-history__col"><p>Rank</p>{{index + 1}}</div>
                <div class="auction-history__col"><p>Bid</p>{{ transaction.value.amount / 1000000}} xtz</div>
                <div class="auction-history__col " >{{transaction.value.bidder.substr(0,3)}}...{{transaction.value.bidder.substr(transaction.value.bidder.length - 4)}}</div>
              </div>
            </div>
          </div>
          <div v-else class="image">
            <img src="../../public/bg/autumn.webp" alt="">
          </div>
        </div>
        <div class="choose-info choose-info-animation">
          <h2>Make Your First Bid</h2>
          <p>
            It's time to connect your Tezos wallet and place your first bet! By placing a bet, you reserve the NFT tree of a particular season.
          </p>
          <p>
            If your bet is outbid, increase it and stay among the winners. The minimum bet increment is 5 xtz. Even if you increase your bet at another season, you continue to compete for the reserved NFT tree image.
          </p>
          <p>
            To compete for the NFT tree of another season, wait until that time of year and place a new bid.
          </p>
          <p>
            If you lose, you can return the xtz to your account.
          </p>

          <a href="/en/more-info">More Info</a>
        </div>
      </div>
    </div>
    <div class="faq-animation-block">
      <div class="faq-animation">
        <Faq />
      </div>
      <div class="auctions" id="auctions">
        <div class="wrapper">
          <div class="auctions-title-animation text-animation">Current Auctions</div>
          <div class="animation-wrapper">
            <img src="../../public/swipe.gif" alt="" class="cursor" id="cursor-footer">
          </div>
          <AuctionCard class="auction-card-animation"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Faq from '../components/Faq.vue'
  import { gsap } from 'gsap';
  import { ScrollTrigger } from 'gsap/ScrollTrigger';
  import { CSSPlugin } from 'gsap/CSSPlugin'
  import AuctionCard from "../components/AuctionCard";
  import { mapActions, mapState } from 'vuex'
  import config from '../config'
  import moment from "moment"
  gsap.registerPlugin(CSSPlugin);
  export default {
    name: 'HomeBody',
    components: {
      AuctionCard,
      Faq,
    },
    data() {
      return {
        width: '627px',
        height: '440px',
        frameAnimationX: 280,
        infoAnimationY: -520,
        infoAnimationY2: -560,
        infoAnimationX: -380,
        contentYPercent: -40,
        textYPercent: -50,
        frameAnimationNone: true,
        componentKey: 0,
        tl: null,
        tlInfo: null,
      }
    },
    computed: {
      ...mapState({
        isConnected: state => state.authWallet.isConnected,
        transactions: state => state.auction.transactions,
      }),
    },
    created() {
      this.getCurrentPlaceTransactions(1);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      const cursor = document.getElementById('cursor-footer')
      const display = window.getComputedStyle(cursor).display
      window.addEventListener("resize", () => {
        if(display === 'block') {
          setTimeout(() => {
            cursor.style.display = 'none';
          },5000)
        }
      });
      if(display === 'block') {
        setTimeout(() => {
          cursor.style.display = 'none';
        },10000)
      }
      this.scrollTrigger()
    },
    methods: {
      ...mapActions({
        getTransactions: 'auction/getTransactions',
      }),
      getCurrentPlaceTransactions(years) {
        let params = {
          target: config['contract_' + years + 'years']
        };
        this.getTransactions(params)
      },
      dateConverter (date) {
        return moment(date).format('l LT')
      },
      seasonConverter (season) {
        if (season === '1') {
          return 'Winter'
        } else if (season === '2') {
          return 'Spring'
        } else if (season === '3') {
          return 'Summer'
        } else {
          return 'Autumn'
        }
      },
      handleResize() {
        let width = window.innerWidth;
        this.topValue = '-750px 95%'
        if (width < 1400) {
          this.height = '330px'
          this.width = '450px'
          this.frameAnimationX = 380
          this.topValue = '-850px 95%'
        }

        if (width < 1452) {
          this.infoAnimationY = -320
          this.frameAnimationX = 290
          this.infoAnimationY2 = -620
          this.infoAnimationX = -380
        }
        if (width < 1200) {
          this.frameAnimationX = 250
          this.infoAnimationX = -280
        }

        if (width < 980) {
          this.frameAnimationX = 190
          this.infoAnimationX = -210
          this.contentYPercent = -35
          this.textYPercent = -35
        }

        if (width < 850) {
          this.frameAnimationNone = false
        } else {
          this.frameAnimationNone = true
        }
        // if (this.tlInfo && this.tl) {
        //   // this.$router.go(0);
        //   this.componentKey += 1
        //   // this.tl.repeatDelay()
        //   // this.tlInfo.repeatDelay()
        //   // window.scrollTo({
        //   //   top: 0,
        //   // });
        // }

      },
      changeDate (value) {
        if (!value) return ''
        return localStorage.getItem(`${value}`)
      },
      scrollTrigger () {
        gsap.registerPlugin(ScrollTrigger)
        ScrollTrigger.defaults({
          start: '20px 80%',
          end: 'bottom 400px',
          scrub: true,
          // markers: true,
        })
        const texts = document.querySelectorAll('.text-animation')
        const card = document.querySelectorAll('.auction-card-animation')
        const pImage = document.querySelectorAll('.pImage')
        const pContent = document.querySelectorAll('.pContent')
        const imgTop = document.querySelectorAll('.img-top')
        const textTop = document.querySelectorAll('.text-top')
        const textTitle = document.querySelectorAll('.text-title')
        const textPhilosophy = document.querySelectorAll('.text-philosophy')
        Array.from(card).map((item) => {
          gsap.to(item, {
            scrollTrigger: {
              start: '-500px 80%',
              end: '100px 400px',
              trigger: item,
              // markers: true,
            },
            duration: 1.5,
            opacity: 0.9,
          });
        })
        Array.from(texts).map((item) => {
          gsap.to(item, {
            scrollTrigger: {
              trigger: item,
            },
            duration: 1.5,
            opacity: 1,
          });
        })
        Array.from(pImage).map((item) => {
          gsap.to(item, {
            yPercent: -20,
            ease: "none",
            scrollTrigger: {
              trigger: item,
            },
          });
        })
        Array.from(pContent).map((item) => {
          gsap.to(item, {
            yPercent: -10,
            ease: "none",
            scrollTrigger: {
              trigger: item,
            },
          });
        })
        Array.from(imgTop).map((item) => {
          gsap.to(item, {
            yPercent: -10,
            ease: "none",
            scrollTrigger: {
              trigger: item,
            },
          });
        })
        Array.from(textTitle).map((item) => {
          gsap.to(item, {
            yPercent: -70,
            ease: "none",
            scrollTrigger: {
              trigger: item,
            },
          });
        })
        Array.from(textPhilosophy).map((item) => {
          gsap.to(item, {
            yPercent: -15,
            scrollTrigger: {
              trigger: item,
            },
          });
        })
        Array.from(textTop).map((item) => {
          gsap.to(item, {
            yPercent: this.textYPercent,
            ease: "none",
            scrollTrigger: {
              trigger: item,
            },
          });
        })

        if (this.frameAnimationNone) {
          this.tl = gsap.timeline({
            scrollTrigger: {
              trigger: '.choose-frame-animation',
            },
          });

          this.tl.to('.choose-frame-animation', {
            direction: 3,
            zIndex: 1
          });

          this.tl.to('.choose-frame-animation', {
            y: 200,
            x: this.frameAnimationX,
            height: this.height,
            width: this.width,
            direction: 3,
          });

          this.tlInfo = gsap.timeline({
            scrollTrigger: {
              trigger: '.choose-frame-animation',
            },
          });

          this.tlInfo.to('.choose-info-animation', {
            x: this.infoAnimationX,
            y: this.infoAnimationY,
            direction: 3,
          });


          this.tlInfo.to('.choose-info-animation', {
            y: this.infoAnimationY2,
            opacity: 1,
            direction: 3,
          });
          setTimeout(() => {
            ScrollTrigger.create({
              trigger: ".faq-animation",
              start: this.topValue,
              end: "+=1500",
              pin: ".choose-animation",
              pinSpacing: false
            });
            // ScrollTrigger.create({
            //   trigger: ".faq-animation",
            //   start: "-10px 95%",
            //   end: "+=1100",
            //   pin: ".choose-animation",
            //   pinSpacing: false,
            //   pinType:'transform',
            //   // markers: true,
            // });
          }, 500)

          gsap.to('.faq-animation', {
            yPercent: -10,
            scrollTrigger: {
              trigger: '.faq-animation',
            },
          })
        }
        gsap.to('.auctions-title-animation', {
          scrollTrigger: {
            trigger: '.auctions-title-animation-start',
            end: "+=500",
            start: '150px 80%'
          },
          fontSize: '40px',
          marginTop: '0',
          marginBottom: '30px',
        });
        gsap.from('.auctions-item', {
          scrollTrigger: {
            trigger: '.auctions-title-animation',
          },
          duration: 1.5,
          opacity: 0,
        });
      },
    }
  }
</script>
<style scoped lang="scss">
  .animation-wrapper {
    height: 35px;
  }
  .cursor {
    display: none;
    @media (max-width: 768px) {
      display: block;
      height: 45px;
      background-color: transparent;
    }
  }
  .auction-card-animation, .text-animation {
    opacity: 0;
  }

  .auction-history {
    grid-column: span 2 / auto;
    @media (max-width: 992px) {
      grid-column: 1;
    }
    @media (max-width: 768px) {
      order: 5;
    }

    .image {
      border-radius: 25px;
      height: 100%;
      img {
        border-radius: 25px;
        height: 100%;
        max-width: 100%;
      }
    }

    &.not-logged {
      .auction-history__cont {
        background: #F9F9F9;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          padding: 0;
        }
      }
      .auction-history__alert {
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          margin-left: 12px;
          font-size: 18px;
          line-height: 21px;
          color: #333333;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          span {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }
    &__cont {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 12px 30px 10px;
      overflow-x: auto;
      overflow-y: visible;
      @media (max-width: 768px) {
        min-height: 150px;
        //height: auto;
        padding: 0 7px 10px;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 2.5px;
        background: #EAEAEA;
      }
      &::-webkit-scrollbar-thumb {
        background: #ED7D37;
        border-radius: 2.5px;
      }
    }
    &__scroll {
      min-width: 1140px;
      @media (max-width: 768px) {
        min-width: 1px;
      }
    }
    &__alert {
      width: 100%;
      height: calc(100% - 37px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      line-height: 21px;
      color: #B1B1B1;
    }
    &__header {
      white-space: nowrap;
      display: flex;
      //justify-content: space-between;
      padding-left: 12px;
      padding-right: 20px;
      padding-bottom: 12px;
      border-bottom: 1px solid #DFDFDF;
      .auction-history__col {
        color: #727A89;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    &__col {
      font-size: 18px;
      line-height: 24px;
      color: #333333;
      position: relative;
      white-space: nowrap;
      span {
        position: absolute;
        top: 0;
        left: 50px;
        @media (max-width: 768px) {
          position: static;
          margin-left: 8px;
        }
      }
      p {
        display: none;
        color: #727A89;
        margin-right: 10px;
        @media (max-width: 768px) {
          display: block;
        }
      }
      @media (max-width: 768px) {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 20px;
      }
      &:nth-child(1) {
        flex-basis: min(210px);
        @media (max-width: 768px) {
          order: 2;
          font-size: 14px;
          line-height: 20px;
          color: #727A89;
          margin-left: auto;
        }
      }
      &:nth-child(2) {
        flex-basis: min(120px);
        @media (max-width: 768px) {
          order: 1;
          font-weight: bold;
        }
      }
      &:nth-child(3) {
        flex-basis: min(120px);
        p {
          width: 55px;
        }
        @media (max-width: 768px) {
          order: 6;
        }
      }
      &:nth-child(4) {
        flex-basis: min(110px);
        p {
          width: 55px;
        }
        @media (max-width: 768px) {
          order: 4;
        }
      }
      &:nth-child(5) {
        flex-basis: min(180px);
        @media (max-width: 768px) {
          order: 5;
        }
      }
      &:nth-child(6) {
        flex-basis: min(120px);
        @media (max-width: 768px) {
          order: 3;
          grid-column: span 2 / auto;
        }
      }
      &:nth-child(7) {
        flex-basis: min(130px);
        @media (max-width: 768px) {
          order: 7;
          grid-column: span 2 / auto;
          justify-content: center;
        }
      }
      &:nth-child(8) {
        flex-basis: min(130px);
        @media (max-width: 768px) {
          order: 7;
          grid-column: span 2 / auto;
          justify-content: center;
        }
      }
      &:nth-child(9) {
        flex-basis: min(130px);
        @media (max-width: 768px) {
          order: 7;
          grid-column: span 2 / auto;
          justify-content: center;
        }
      }
      &.active {
        color: #46C07C;
      }
      &.fault {
        color: #FF0000;
      }
      &.change {
        span {
          color: #ED7D37;
        }
      }
    }
    &__items {
      overflow-y: auto;
      max-height: 80%;
      height: 100%;
      margin-top: 10px;
      padding-right: 20px;
      /*@media (max-width: 768px) {*/
      /*  max-height: 500px;*/
      /*}*/
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 1px;
        background: #EAEAEA;
      }
      &::-webkit-scrollbar-thumb {
        background: #ED7D37;
        border-radius: 1px;
      }
      @media (max-width: 768px) {
        padding-right: 8px;
      }
    }
    &__item {
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding: 0 0 0 12px;
      height: 52px;
      //border: 1px solid transparent;
      border-bottom: 1px solid #DFDFDF;
      cursor: default;
      @media (max-width: 768px) {
        height: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
        padding: 12px 8px;
      }
    }
    &__btn {
      padding: 5px 16px;
      border-radius: 5px;
      font-size: 18px;
      line-height: 24px;
      transition: .35s;
      &.increase {
        background: rgba(237, 125, 55, 0.1);
        border-radius: 5px;
        border: 1px solid transparent;
        color: #ED7D37;
        margin-right: 25px;
        &:hover {
          border: 1px solid #ED7D37;
        }
      }
      &.withdraw {
        color: #333333;
        &:hover {
          color: #ED7D37;
        }
      }
    }
  }

  .header-info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .choose-info-animation {
    @media (max-width: 800px) {
      opacity: 1;
    }
    opacity: 0;
  }
  .auctions-title-animation {
    margin-top: 120px;
    margin-bottom: 346px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .header {
    &-info {
      .wrapper {
        text-align: center;
        width: 100%;
      }
      &.home {
        padding: 7px 0;
        font-size: 18px;
        line-height: 21px;
        color: #676767;
        background: #F7F7F7;
        .wrapper {
          justify-content: center;
        }
        @media (max-width: 576px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  .about {
    margin: 95px 0 185px;
    @media (max-width: 800px) {
      margin: 60px 0;
    }
    .wrapper {
      flex-direction: column;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;

      @media (max-width: 800px) {
        display: flex;
        align-items: flex-start;
      }
    }
    &-info {
      @media (max-width: 800px) {
        &:nth-of-type(1) {
          order: 2;
        }
        &:nth-of-type(2) {
          order: 4;
        }
        &:nth-of-type(3) {
          order: 6;
        }
      }
      &.left {
        padding-left: 80px;
        @media (max-width: 800px) {
          padding-left: 0;
        }
      }
      &__title {
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        color: #000000;
        @media (max-width: 450px) {
          font-size: 22px;
          line-height: 26px;
        }

      }
      &__desc {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          span {
            font-weight: bold;
            &.season {
              font-weight: normal;
              &.winter {
                color: #0f81cd;
              }
            }
          }
        }
      }
    }
    &-img {
      width: 100%;
      height: 420px;
      object-fit: contain;
      @media (max-width: 800px) {
        &:nth-of-type(1) {
          order: 1;
        }
        &:nth-of-type(2) {
          order: 3;
        }
        &:nth-of-type(3) {
          order: 5;
        }
      }
      @media (max-width: 450px) {
        height: 251px;
      }

    }
  }
  .participate {
    .wrapper {
      flex-direction: column;
    }
    &-title {
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      color: #000000;

      @media (max-width: 450px) {
        font-size: 30px;
        line-height: 36px;
      }

    }
    &-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 210px;
      margin: 60px 0 100px;
      width: 100%;

      @media (max-width: 1000px) {
        grid-gap: 50px;
      }

      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        div:nth-child(1) {
          order: 1;
          margin-left: 0;
        }
        div:nth-child(2) {
          order: 2;
        }
        div:nth-child(3) {
          order: 4;
          margin-left: auto;
          max-width: 320px;
          text-align: left;
        }
        div:nth-child(4) {
          order: 3;
          margin-right: 0;
          margin-left: 0;
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
        div:nth-child(5) {
          order: 5;
          margin: 0;
        }
        div:nth-child(6) {
          order: 6;
          max-width: 320px;
        }
        div:nth-child(7) {
          order: 8;
          margin-left: auto;
          margin-right: 0;
          max-width: 320px;
          text-align: left;
        }
        div:nth-child(8) {
          order: 7;
          margin-left: auto;
          margin-right: 0;
        }

      }


      @media (max-width: 450px) {

        div:nth-child(2) {
          max-width: 229px;
        }
        div:nth-child(3) {
          max-width: 229px;
        }
        div:nth-child(6) {
          max-width: 229px;
        }
        div:nth-child(7) {
          max-width: 229px;
        }
      }

      &__img {
        object-fit: cover;
        position: relative;
        .image {
          height: 255px;
          width: 325px;
          object-fit: contain;

          @media (max-width: 450px) {
            height: 151px;
            width: 192px;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: url("../../public/ellipse.svg") no-repeat center center;
          width: 17px;
          height: 17px;
          z-index: 3;

          @media (max-width: 1000px) {
            display: none;
          }

        }
        &.left {
          margin-right: 25px;
          margin-left: auto;
          &:after {
            right: -139px;
          }
        }
        &.right {
          margin-left: 25px;
          margin-right: auto;
          &:after {
            left: -139px;
          }
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: calc(100% + 208px);
          border: 1px dashed #D9D9D9;
          z-index: 2;

          @media (max-width: 1000px) {
            display: none;
          }

        }
        &.semi {
          &-start {
            &:after {
              transform: translateY(0);
              top: calc(50% + 8.5px);
              height: calc(50% + 105px);
            }
          }
          &-end {
            &:after {
              transform: translateY(0);
              top: auto;
              bottom: calc(50% + 8.5px);
              height: calc(50% + 105px);
            }
          }
        }
        h3 {
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          color: #000000;
          margin-bottom: 16px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
        &.left {
          text-align: right;
          &:after {
            right: -106px;
          }
        }
        &.right {
          text-align: left;
          &:after {
            left: -106px;
          }
        }
      }
    }
  }
  .choose {
    margin-bottom: 400px;
    @media (max-width: 800px) {
      margin-bottom: 0;
    }
    .wrapper {
      flex-direction: column;

      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }
    &-frame {
      height: 600px;
      max-width: 800px;
      width: 100%;
      box-shadow: 0px 0px 42px rgba(0, 0, 0, 0.15);
      background: #fff;
      border-radius: 30px;

      @media (max-width: 800px) {
        height: 100%;
        max-width: 450px;
        width: 100%;
      }

      @media (max-width: 500px) {
        height: 100%;
        max-width: 351px;
        width: 100%;
      }


    }
    &-info {
      margin: 100px 0 180px;
      max-width: 410px;
      h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: #000000;
        margin-bottom: 6px;
      }
      p {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
      a {
        display: inline-block;
        background: #ED7D37;
        border-radius: 5px;
        text-decoration: none;
        color: #fff;
        font-size: 18px;
        line-height: 21px;
        padding: 12px 22px;
        transition: .35s;
        &:hover {
          background: #E07735;
        }
      }
    }
  }
  .auctions {
    margin: 40px 0 65px;
    padding-bottom: 50px;
    overflow: hidden !important;
    .wrapper {
      flex-direction: column;
      row-gap: 15px;
    }
    &-title {
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      color: #000000;

    }
    &-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &-auction {
      flex-basis: 30%;
      background: #F9F9F9;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      overflow: hidden;
      &__image {
        width: 100%;
        height: 405px;
        object-fit: cover;
      }
      &__body {
        padding: 0 20px 16px;
      }
      &__name {
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #000000;
        margin: 0;
        padding: 16px 0;
        text-align: center;
        font-weight: normal;
        span {
          font-weight: bold;
        }
      }
      &__timer {
        padding: 12px 0;
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
        @media (max-width: 768px) {
          padding: 10px 0;
        }
      }
      &__btns {
        padding: 16px 24px 0;
        display: flex;
        justify-content: center;
      }
      &__bid {
        display: block;
        padding: 10px 26px;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        background: #ED7D37;
        border-radius: 5px;
        white-space: nowrap;
        &:hover {
          box-shadow: 0px 0px 10px rgba(237, 125, 55, 0.5);
          padding: 12px 36px;
          margin: -2px -10px -2px;
        }
      }
    }
  }
  .flip-clock {
    margin-left: -7px !important;
    margin-right: -7px !important;
  }
  
  
  @media (max-width: 768px) {
    /*.main-auction__image{*/
    /*  height: 200px;*/
    /*}*/
  }
</style>
