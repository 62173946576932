var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader"
  }, [_c('header-loading'), _c('div', {
    staticClass: "preloader-text"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mt-6",
    attrs: {
      "type": "rect",
      "width": 438,
      "height": 21,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  })], 1), _c('div', {
    staticClass: "preloader-main"
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": 425,
      "height": 40,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "preloader-main-text",
    attrs: {
      "type": "rect",
      "width": 438,
      "height": 21,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('div', {
    staticClass: "preloader-main-items"
  }, _vm._l(3, function (i) {
    return _c('div', {
      key: i,
      staticClass: "preloader-main-items-item"
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": 350,
        "height": 405,
        "rounded": true,
        "radius": 20,
        "animation": "wave"
      }
    }), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 198,
        "height": 30,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    }), _c('div', {
      staticClass: "block"
    }, _vm._l(4, function (i) {
      return _c('div', {
        key: i,
        staticClass: "block-item"
      }, [_c('vue-skeleton-loader', {
        attrs: {
          "type": "rect",
          "width": 43,
          "height": 53,
          "rounded": true,
          "radius": 5,
          "animation": "wave"
        }
      }), _c('vue-skeleton-loader', {
        staticClass: "mt-10",
        attrs: {
          "type": "rect",
          "width": 34,
          "height": 16,
          "rounded": true,
          "radius": 5,
          "animation": "wave"
        }
      })], 1);
    }), 0), _c('vue-skeleton-loader', {
      staticClass: "block",
      attrs: {
        "type": "rect",
        "width": 160,
        "height": 45,
        "rounded": true,
        "radius": 5,
        "animation": "wave"
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }