<template>
  <div>
    <main-loading v-if="loader"/>
    <main class="main">
    <main-bg/>
    <div class="wrapper">
      <h1 class="main-title">Longest Auction Ever</h1>
      <p class="main-subtitle">We have created art woven from time</p>
      <div class="animation-wrapper">
        <img src="../../public/swipe.gif" alt="" class="cursor" id="cursor">
      </div>
      <AuctionCard/>
    </div>
    </main>
  </div>
</template>
<script>
import AuctionCard from "./AuctionCard";
import MainBg from "./MainBg";
import MainLoading from "./Loading/MainLoading";
export default {
  name: 'Main',
  components: {
    MainLoading,
    MainBg,
    AuctionCard
  },
  data () {
    return {
      loader: true
    }
  },
  mounted() {
    const cursor = document.getElementById('cursor')
    const display = window.getComputedStyle(cursor).display
    window.addEventListener("resize", () => {
      if(display === 'block') {
        setTimeout(() => {
          cursor.style.display = 'none';
        },5000)
      }
    });
    if(display === 'block') {
      setTimeout(() => {
        cursor.style.display = 'none';
      },10000)
    }
    setTimeout(() => {
      this.loader = false
    }, 1000)
  }
}
</script>
<style scoped lang="scss">
.animation-wrapper {
  height: 35px;
}
.cursor {
  display: none;
  @media (max-width: 768px) {
    display: block;
    height: 45px;
    background-color: transparent;
  }
}

main {
  overflow: hidden !important;
}
.main {
  padding: 36px 0 90px;
  background-repeat: no-repeat;
  animation-duration: 4s;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: relative;
  .wrapper {
    flex-direction: column;
  }
  &-title {
    margin: 0 0 12px;
    font-weight: bold;
    font-size: 45px;
    line-height: 55px;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 450px) {
      font-size: 30px;
      line-height: 36px;
    }

  }
  &-subtitle {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
  //&-learn {
  //  display: flex;
  //  align-items: center;
  //  text-decoration: none;
  //  &:hover {
  //    text-decoration: underline;
  //    color: #004EDA;
  //  }
  //  svg {
  //    margin-left: 10px;
  //  }
  //}
}
.flip-clock {
  margin-left: -7px !important;
  margin-right: -7px !important;
}

@media (max-width: 768px) {
  .main{
    padding: 15px 0;
  }
}
</style>
