var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "choose choose-animation"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "choose-frame choose-frame-animation auction-history"
  }, [_vm.transactions && _vm.transactions.length ? _c('div', {
    staticClass: "auction-history__cont"
  }, [_vm.transactions && _vm.transactions.length ? _c('div', {
    staticClass: "auction-history__header"
  }, [_c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.date")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.season")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.rank")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.bid")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.address")))])]) : _vm._e(), _vm.transactions && _vm.transactions.length ? _c('div', {
    staticClass: "auction-history__items"
  }, _vm._l(_vm.transactions, function (transaction, index) {
    return _c('div', {
      key: index,
      staticClass: "auction-history__item"
    }, [_c('div', {
      staticClass: "auction-history__col"
    }, [_vm._v(_vm._s(_vm._f("moment")(transaction.value.ts, "from", "now")))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_c('p', [_vm._v("Season")]), _vm._v(_vm._s(_vm.seasonConverter(transaction.value.season)))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_c('p', [_vm._v("Rank")]), _vm._v(_vm._s(index + 1))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_c('p', [_vm._v("Bid")]), _vm._v(_vm._s(transaction.value.amount / 1000000) + " xtz")]), _c('div', {
      staticClass: "auction-history__col "
    }, [_vm._v(_vm._s(transaction.value.bidder.substr(0, 3)) + "..." + _vm._s(transaction.value.bidder.substr(transaction.value.bidder.length - 4)))])]);
  }), 0) : _vm._e()]) : _c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/bg/autumn.webp"),
      "alt": ""
    }
  })])]), _vm._m(2)])]), _c('div', {
    staticClass: "faq-animation-block"
  }, [_c('div', {
    staticClass: "faq-animation"
  }, [_c('Faq')], 1), _c('div', {
    staticClass: "auctions",
    attrs: {
      "id": "auctions"
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "auctions-title-animation text-animation"
  }, [_vm._v("Current Auctions")]), _vm._m(3), _c('AuctionCard', {
    staticClass: "auction-card-animation"
  })], 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "about section"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "about-info left text-animation pContent"
  }, [_c('h2', {
    staticClass: "about-info__title"
  }, [_vm._v("What is going here?")]), _c('div', {
    staticClass: "about-info__desc"
  }, [_c('p', [_vm._v("We have launched the longest auction on the Tezos blockchain featuring 111 NFTs.")]), _c('p', [_c('span', [_vm._v("1-YEAR-Auction")]), _vm._v(" - 100 winners")]), _c('p', [_c('span', [_vm._v("10-YEAR-Auction")]), _vm._v(" - 10 winners")]), _c('p', [_c('span', [_vm._v("100-YEAR-Auction")]), _vm._v(" - 1 winners")])])]), _c('img', {
    staticClass: "about-img pImage text-animation",
    attrs: {
      "src": require("../../public/home/what-is-going-here.webp"),
      "alt": "What is going here?"
    }
  }), _c('img', {
    staticClass: "about-img pImage text-animation",
    attrs: {
      "src": require("../../public/home/philosophy.webp"),
      "alt": "Philosophy"
    }
  }), _c('div', {
    staticClass: "about-info text-animation text-philosophy"
  }, [_c('h2', {
    staticClass: "about-info__title"
  }, [_vm._v("Philosophy")]), _c('div', {
    staticClass: "about-info__desc"
  }, [_c('p', [_vm._v("Our auctions are a tribute to the priceless essence of time, uniqueness, and creativity. By participating in these long-term auctions, you become part of a peculiar creative journey that results in the acquisition of a unique image of the NFT tree. ")]), _c('p', [_vm._v("Blockchain technology, due to its decentralized and secure nature, is here to stay and is likely to play a major role in the years to come. However, the value of the NFT lies not merely in the image itself, but in the process of acquiring it. Thus, time legitimizes the value of the prize.")]), _c('p', [_vm._v(" This is what makes the auction an exceptional event. Essentially, we have managed to create art woven from time. ")])])]), _c('div', {
    staticClass: "about-info left text-animation pContent"
  }, [_c('h2', {
    staticClass: "about-info__title"
  }, [_vm._v("Philosophy")]), _c('div', {
    staticClass: "about-info__desc"
  }, [_c('p', [_vm._v(" The lengthy life cycle of a tree is an evocative metaphor for the transience of time. To better reflect our idea, we have featured: ")]), _c('ul', [_c('li', [_vm._v("Young trees for the one-year auction;")]), _c('li', [_vm._v("Trees with a more resilient root system and a large crown for the 10-year auction;")]), _c('li', [_vm._v("A tree with a robust trunk and dense foliage for the 100-year auction.")])]), _c('p', [_vm._v(" Each NFT tree is unique and represented according to the season in which you are placing your bet, as the changing of the seasons and the transience of time are tightly bound together. ")]), _c('p', [_vm._v(" You can't play with time and you can't cheat. To compete for a tree of a particular season, you have to wait for that time of year and place your bet. ")])])]), _c('img', {
    staticClass: "about-img pImage text-animation",
    attrs: {
      "src": require("../../public/home/seasons.webp"),
      "alt": "Seasons"
    }
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "participate section"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('h2', {
    staticClass: "participate-title text-animation text-top"
  }, [_vm._v("How to participate?")]), _c('div', {
    staticClass: "participate-content"
  }, [_c('div', {
    staticClass: "participate-content__img left"
  }, [_c('img', {
    staticClass: "image img-top text-animation",
    attrs: {
      "src": require("../../public/home/connect-wallet.webp"),
      "alt": "Connect wallet and make a bid"
    }
  })]), _c('div', {
    staticClass: "participate-content__info right semi-start"
  }, [_c('h3', {
    staticClass: "text-animation text-title"
  }, [_vm._v("Connect wallet and make a bid")]), _c('p', {
    staticClass: "text-animation text-top"
  }, [_vm._v("Connect a wallet with the required amount of Tezos for the minimum bid. Place a bid on the page of the desired auction.")])]), _c('div', {
    staticClass: "participate-content__info left"
  }, [_c('h3', {
    staticClass: "text-animation text-title"
  }, [_vm._v("NFT Reservation")]), _c('p', {
    staticClass: "text-animation text-top"
  }, [_vm._v("After you have placed a bid, you have successfully reserved an NFT with the corresponding time of the year.")]), _c('p', {
    staticClass: "text-animation text-top"
  }, [_vm._v("For example, if you are betting in the summer, you will be competing for the summer NFT tree (even if you plan to increase the bid at a different time of year).")]), _c('div', {
    staticClass: "after"
  })]), _c('div', {
    staticClass: "participate-content__img right"
  }, [_c('img', {
    staticClass: "image img-top text-animation",
    attrs: {
      "src": require("../../public/home/nft-reserve.webp"),
      "alt": "NFT reserve"
    }
  })]), _c('div', {
    staticClass: "participate-content__img left"
  }, [_c('img', {
    staticClass: "image img-top text-animation",
    attrs: {
      "src": require("../../public/home/compete-for-places.webp"),
      "alt": "Compete for the prize places"
    }
  })]), _c('div', {
    staticClass: "participate-content__info right"
  }, [_c('h3', {
    staticClass: "text-animation text-title"
  }, [_vm._v("Be on the Winning Side of the Auction")]), _c('p', {
    staticClass: "text-animation text-top"
  }, [_vm._v("Keep an eye on the progress of the auction to stay among the winners!")]), _c('p', {
    staticClass: "text-animation text-top"
  }, [_vm._v("If you are outbid, increase the bid or make a new one to get back in the winner's list.")])]), _c('div', {
    staticClass: "participate-content__info left semi-end"
  }, [_c('h3', {
    staticClass: "text-animation text-title"
  }, [_vm._v("Get your NFT")]), _c('p', {
    staticClass: "text-animation text-top"
  }, [_vm._v("If you are one of the winners, open your Profile to see the NFT you have won.")])]), _c('div', {
    staticClass: "participate-content__img right"
  }, [_c('img', {
    staticClass: "image img-top text-animation",
    attrs: {
      "src": require("../../public/home/get-nft.webp"),
      "alt": "Get your NFT"
    }
  })])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "choose-info choose-info-animation"
  }, [_c('h2', [_vm._v("Make Your First Bid")]), _c('p', [_vm._v(" It's time to connect your Tezos wallet and place your first bet! By placing a bet, you reserve the NFT tree of a particular season. ")]), _c('p', [_vm._v(" If your bet is outbid, increase it and stay among the winners. The minimum bet increment is 5 xtz. Even if you increase your bet at another season, you continue to compete for the reserved NFT tree image. ")]), _c('p', [_vm._v(" To compete for the NFT tree of another season, wait until that time of year and place a new bid. ")]), _c('p', [_vm._v(" If you lose, you can return the xtz to your account. ")]), _c('a', {
    attrs: {
      "href": "/en/more-info"
    }
  }, [_vm._v("More Info")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animation-wrapper"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": require("../../public/swipe.gif"),
      "alt": "",
      "id": "cursor-footer"
    }
  })]);
}]

export { render, staticRenderFns }